import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export class Contacts extends Component {
	render() {
		return (
			<>
				<Container maxWidth='xl'>
					<Helmet>
						<title>Контакты</title>
						<meta name='description' content='Это страница контактов.' />
					</Helmet>
					<Box >
						<Box className='row'>
							<Box className='col-12'>
								<h1 style={{ marginBottom: '20px', fontWeight: '400' }}>
									Контакты
								</h1>
								<p>
									Телефон:
									<a href='tel: +7 (901) 459-7662 ;'> +7 (901) 459-7662</a>
								</p>
								<p>
									Почта:{' '}
									<a className='' href='mailto: ava-drive@mail.ru'>
										ava-drive@mail.ru
									</a>
								</p>

								<Box className='mt-3 mb-4'>
									<p>Мы находимся Павловский тракт 313/2</p>
									<p>Автосалон находится напротив ТЦ Арена</p>
									<p>Часы работы: с 9:00 до 19:00</p>
									<p>Без выходных и обеда</p>
									<YMaps
										query={{ apikey: '64d635a0-4beb-4eb7-9cc4-b20453910bb3' }}
									>
										<Box mt={2}>
											<Map
												defaultState={{
													center: [53.34696262457308, 83.63594384079042],
													zoom: 16,
												}}
												width='100%'
												height='350px'
											>
												<Placemark
													defaultGeometry={[
														53.34696262457308, 83.63594384079042,
													]}
												/>
											</Map>
										</Box>
									</YMaps>
								</Box>
							</Box>
						</Box>
					</Box>
				</Container>
			</>
		)
	}
}

export default Contacts

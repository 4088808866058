import { Box, Container } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'

const Opening = () => {
	return (
		<Container maxWidth='xl' fluid='md'>
			<Helmet>
				<title>Свежие вакансии</title>
				<meta name='description' content='Свежие вакансии' />
			</Helmet>
			<h1 style={{ fontWeight: '400' }}>Свежие вакансии в Ava Drive</h1>
			<Box sx={{ textAlign: 'center', mt: 10 }}>
				В данный момент нет доступных вакансий. Следите за обновлениями.😘
			</Box>
		</Container>
	)
}

export default Opening

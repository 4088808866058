import { Avatar, Box, Grid, Rating } from '@mui/material'
import { Container } from '@mui/system'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useNavigate, useParams } from 'react-router-dom'
import axios, { linkDev } from '../../axios'
import { CarPhoneFullPost } from '../../components/Block/CarPhoneFullPost'
import SkeletonFullPost from './Skeleton.FullPost'

export const FullPost = () => {
	const [value, setValue] = React.useState(5)
	const [isShowForm, setIsShowForm] = React.useState(false)
	const [data, setData] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const { id } = useParams()
	const [reviews, setReviews] = useState([])
	const [valueReview, setValueReview] = React.useState(5)
	const navigate = useNavigate()
	const [name, setName] = React.useState('')
	const [text, setText] = React.useState('')

	React.useEffect(() => {
		axios
			.get(`/reviews/${id}`)
			.then(res => setReviews(res.data))
			.catch(err => console.warn(err))
	}, [id])
	React.useEffect(() => {
		axios
			.get(`/posts/${id}`)
			.then(res => {
				setData(res.data)
				setIsLoading(false)
				window.scrollTo(0, 0)
			})
			.catch(err => {
				console.warm(err)
				alert('ошибка при получении поста')
			})
	}, [id])

	if (isLoading) {
		return (
			<Container maxWidth='xl'>
				<SkeletonFullPost />
			</Container>
		)
	}
	const handleSubmitReview = async () => {
		const reviewData = {
			productId: id,
			name: name,
			text: text,
		}

		try {
			await axios.post('/review', reviewData)
		} catch (error) {
			console.error(error)
			alert('Ошибка при отправке отзыва')
		}
	}

	console.log(data)
	return (
		<>
			<Container maxWidth='xl'>
				<Helmet>
					<meta property='og:title' content='Ваш лучший выбор Ava Drive' />
					<meta
						property='og:description'
						content='AVA DRIVE - честный автосалон в городе Барнаул. Компания предлагает широкий ассортимент легковых автомобилей иностранного производства, а так же квадроциклов и скутеров. Осуществляет разнообразный спектр услуг: продажа, срочный выкуп автомобилей с пробегом, оценка и обмен, автокредитование, покупупка квадроцикла или скутера'
					/>
					<title>{data.title}</title>
					<meta
						name='description'
						content='Покупка автомобилей и квадроциклов по низким ценам'
					/>
				</Helmet>
				<Grid container spacing={2}>
					<Grid item xs={12} md={8}>
						<Box className='wrapperImage'>
							<Splide>
								<SplideSlide>
									<img
										className='FullImage'
										width='100%'
										src={data.imageUrl ? `${linkDev}${data.imageUrl}` : ''}
										alt='slide'
									/>
								</SplideSlide>
								{data.imageUrl2.length > 1 && (
									<SplideSlide>
										<img
											className='FullImage'
											width='100%'
											src={data.imageUrl ? `${linkDev}${data.imageUrl2}` : ''}
											alt='slide'
										/>
									</SplideSlide>
								)}
								{data.imageUrl3.length > 1 && (
									<SplideSlide>
										<img
											className='FullImage'
											width='100%'
											src={data.imageUrl ? `${linkDev}${data.imageUrl3}` : ''}
											alt='slide'
										/>
									</SplideSlide>
								)}
								{data.imageUrl4.length > 1 && (
									<SplideSlide>
										<img
											className='FullImage'
											width='100%'
											src={data.imageUrl ? `${linkDev}${data.imageUrl4}` : ''}
											alt='slide'
										/>
									</SplideSlide>
								)}
								{data.imageUrl5.length > 1 && (
									<SplideSlide>
										<img
											className='FullImage'
											width='100%'
											src={data.imageUrl ? `${linkDev}${data.imageUrl5}` : ''}
											alt='slide'
										/>
									</SplideSlide>
								)}
							</Splide>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box>
							<Box>
								<h1 variant='h4' style={{ fontWeight: '500' }}>
									{data.title}
								</h1>
								<p style={{ fontSize: '24px' }}>
									Цена: {data.price.toLocaleString('ru-RU')} ₽
								</p>
							</Box>
							<CarPhoneFullPost game={data} />

							{isShowForm && (
								<div>
									<input type='text' placeholder='имя' />
									<input type='text' placeholder='номер телефона' />
									<button>отправить</button>
								</div>
							)}
						</Box>
						<Box className='mt-3'>
							<p className='titleMain2'>Технические характеристики:</p>
							<Box className='BoxDescription'>
								{data?.engine?.length > 0 ? (
									<p>Двигатель: {data.engine} л.</p>
								) : (
									''
								)}
								{data?.power?.length > 0 ? (
									<p>Мощность: {data.power} л.с </p>
								) : (
									''
								)}

								{data?.transmission?.length > 0 ? (
									<p>Трансмиссия: {data.transmission} </p>
								) : (
									''
								)}

								{data?.drive?.length > 0 ? <p>Привод: {data.drive} </p> : ''}

								{data?.mileage?.length > 0 ? (
									<p>Пробег: {data.mileage} км</p>
								) : (
									''
								)}
								{data?.steeringWheel?.length > 0 ? (
									<p>Руль: {data.steeringWheel} </p>
								) : (
									''
								)}
								{data.color?.length > 0 ? <p>Цвет: {data.color} </p> : ''}

								{data?.engineBike?.length > 0 ? (
									<p>Двигатель тип {data.engineBike} </p>
								) : (
									''
								)}

								{data?.volumePower?.length > 0 ? (
									<p>Объем / мощность: {data.volumePower} см3 </p>
								) : (
									''
								)}

								{data?.maximumLoad?.length > 0 ? (
									<p>Максимальная нагрузка: {data.maximumLoad} кг </p>
								) : (
									''
								)}

								{data?.maximumSpeed?.length > 0 ? (
									<p>Максимальная скорость: {data.maximumSpeed} км/ч </p>
								) : (
									''
								)}
							</Box>
						</Box>
					</Grid>
				</Grid>
				<Grid item xs={12} md={12}>
					<p
						className='titleMain2'
						style={{ marginTop: '20px', marginBottom: '20px' }}
					>
						Описание {data.category === '5' && 'квадроцикла:'}
						{data.category === '6' && 'скутера:'}
						{data.category === '2' && 'автомобиля:'}
						{data.category === '3' && 'автомобиля:'}
						{data.category === '1' && 'автомобиля:'}
						{data.category === '4' && 'автомобиля:'}
					</p>

					<ReactMarkdown children={data.text} />
					<p
						className='titleMain2'
						style={{ marginTop: '20px', marginBottom: '20px' }}
					>
						Отзывы:
					</p>
					{reviews.length === 0 ? (
						<p style={{ fontSize: '16px' }}>Ничего нет</p>
					) : (
						''
					)}

					<Row>
						<Col xs={12} lg={6} md={6} xl={6}>
							<form onSubmit={handleSubmitReview}>
								<Rating
									name='simple-controlled'
									value={value}
									onChange={(event, newValue) => {
										setValue(newValue)
									}}
								/>
								<p style={{ fontSize: '24px', margin: '0' }}>
									Хотите оставить отзыв?
								</p>
								<input
									className='inputLogin mt-1 mb-4'
									type='text'
									placeholder='Ваше имя'
									onChange={e => setName(e.target.value)}
									value={name}
								/>
								<textarea
									onChange={e => setText(e.target.value)}
									value={text}
									className='inputLogin mb-3'
									name='send'
									id='send'
									placeholder='Введите текст'
								/>
								<button className='btn2 dopp mb-3' type='submit'>
									Отправить
								</button>
							</form>
						</Col>
					</Row>
					{reviews.map(review => (
						<div key={review._id} className='ratingBlock'>
							<Avatar alt='Remy Sharp' sx={{ width: 50, height: 50, mb: 2 }} />
							<Rating name='read-only' value={valueReview} readOnly />
							<p style={{ fontSize: '20px', fontWeight: '400' }}>
								{review.name}
							</p>

							<p style={{ fontSize: '14px', fontWeight: '200' }}>
								{review.text}
							</p>
						</div>
					))}
				</Grid>
			</Container>
		</>
	)
}

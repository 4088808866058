import { Box } from '@mui/material'
import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Container } from '@mui/system'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { MdClear } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { linkDev } from '../../axios'
import { Card } from '../../components/Card/Card'
import TextDesc from '../../components/Text/TextDesc'
import TextDescCar from '../../components/Text/TextDescCar'
import TextDescScooter from '../../components/Text/TextDescScooter'
import avadrive from '../../img/avadrive.png'
import { fetchPosts } from '../../store/slices/posts'
import stylesCategory from './home-page.module.scss'

const categoryMapping = {
	all: '0',
	withmileage: '1',
	nomileage: '2',
	quadbike: '5',
	scooter: '6',
	new: '3',
	specialoffers: '4',
}

const categoryLabels = {
	all: 'все',
	withmileage: 'с пробегом',
	nomileage: 'без пробега',
	quadbike: 'квадроциклы',
	scooter: 'скутеры',
	new: 'новинки',
	specialoffers: 'спецпредложения',
}

export const HomePage = () => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const navigate = useNavigate()
	const { categoryId } = useParams()
	const [searchValue, setSearchValue] = useState('')
	const userData = useSelector(state => state.auth.data)
	const dispatch = useDispatch()
	const [activeIndex, setActiveIndex] = React.useState(categoryId || '0')
	const { posts } = useSelector(state => state.posts)
	const isPostsLoading = posts.status === 'loading'
	const [data, setData] = useState(posts.items)
	const [loading, setLoading] = React.useState(false)
	const [selectedCategory, setSelectedCategory] = useState('')
	const [selectedTag, setSelectedTag] = useState('')
	const [maximumSpeed, setMaximumSpeed] = useState(null)
	const [maximumLoad, setMaximumLoad] = useState(null)
	const [volumePower, setVolumePower] = useState(null)
	const [engineBike, setEngineBike] = useState(null)

	const handleTagClick = tag => {
		const filteredData = posts.items.filter(item => item.tags.includes(tag))
		setSelectedTag(tag)
		setData(filteredData)
	}
	React.useEffect(() => {
		window.scrollTo(0, 0)
		dispatch(fetchPosts()).then(() => {
			const currentCategory = categoryId || 'all'
			filterResult(currentCategory)
		})
	}, [dispatch, categoryId])

	React.useEffect(() => {
		if (posts.items.length) {
			const currentCategory = categoryId || 'all'
			filterResult(currentCategory)
		}
	}, [posts.items])

	const handleSortPriceLow = () => {
		setAnchorEl(null)
		setLoading(true)
		setData(data.sort((a, b) => a.price - b.price))
		setLoading(false)
	}

	const handleSortPriceHigh = () => {
		setAnchorEl(null)
		setLoading(true)
		setData(data.sort((a, b) => b.price - a.price))
		setLoading(false)
	}

	const filterResult = categoryName => {
		if (!posts.items.length) return

		const catId = categoryMapping[categoryName]
		const result = posts.items.filter(curData => {
			if (curData.category === catId) return true
			else if (catId === '0') return true
			else return false
		})

		setData(result)
		setActiveIndex(categoryName)
		setSelectedCategory(categoryName)
		setSelectedTag('')
		setMaximumSpeed(null)
		setMaximumLoad(null)
		setVolumePower(null)
		setEngineBike(null)

		if (categoryName) {
			navigate(`/search/${categoryName}`)
		} else {
			navigate(`/search/all`)
		}
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDefaultResult = () => {
		setAnchorEl(null)
		setLoading(true)
		setData(data)
		setLoading(false)
	}

	const filterByCriteria = () => {
		return data.filter(obj => {
			const matchesSearch = obj.title
				.toLowerCase()
				.includes(searchValue.toLowerCase())
			const matchesMaximumSpeed = maximumSpeed
				? obj.maximumSpeed == maximumSpeed
				: true
			const matchesMaximumLoad = maximumLoad
				? obj.maximumLoad == maximumLoad
				: true
			const matchesVolumePower = volumePower
				? obj.volumePower == volumePower
				: true
			const matchesEngineBike = engineBike
				? obj.engineBike === engineBike
				: true

			return (
				matchesSearch &&
				matchesMaximumSpeed &&
				matchesMaximumLoad &&
				matchesVolumePower &&
				matchesEngineBike
			)
		})
	}

	return (
		<Container maxWidth='xl' fluid='md'>
			<Helmet>
				<title>Поиск {categoryLabels[selectedCategory] || 'автомобили'}</title>
				<meta
					name='description'
					content='Поиск автомобилей, квадроцилков и скутеров'
				/>
			</Helmet>
			<>
				<Box className='mainblocke contentAnim'>
					<Box>
						<p className='titleMainh1'>
							Поиск {categoryLabels[selectedCategory] || 'автомобили'}
						</p>
						<Box className='searchWr'>
							<input
								value={searchValue}
								onChange={event => setSearchValue(event.target.value)}
								className='inputSearch mb-3'
								placeholder='Поиск'
							/>
							{searchValue && (
								<Box className='clearSearch' onClick={() => setSearchValue('')}>
									{' '}
									<MdClear size={25} />{' '}
								</Box>
							)}
							<Box>
								<Box>
									<button
										style={{ marginLeft: '4px' }}
										className='inputSearch hoverbutton mobileText'
										id='fade-button'
										aria-controls={open ? 'fade-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									>
										Сортировать
									</button>
									<Menu
										sx={{
											mt: '1px',
											'& .MuiMenu-paper': {
												backgroundColor: '#0f0f10',
												color: 'white',
												border: 'none',
												padding: '5px',
												marginTop: '8px',
											},
										}}
										id='fade-menu'
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										TransitionComponent={Fade}
										MenuListProps={{ 'aria-labelledby': 'header-avatar-menu' }}
										anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
										transformOrigin={{ vertical: 'top', horizontal: 'right' }}
									>
										<MenuItem onClick={handleDefaultResult}>
											По умолчанию
										</MenuItem>
										<MenuItem onClick={handleSortPriceLow}>
											По убыванию цены
										</MenuItem>
										<MenuItem onClick={handleSortPriceHigh}>
											По возрастанию цены
										</MenuItem>
									</Menu>
								</Box>
							</Box>
						</Box>

						<Box className={stylesCategory.categories}>
							<ul>
								<li
									className={activeIndex === 'all' ? 'active' : ''}
									onClick={() => filterResult('all')}
								>
									Все
								</li>
								<li
									className={activeIndex === 'withmileage' ? 'active' : ''}
									onClick={() => filterResult('withmileage')}
								>
									С пробегом
								</li>
								<li
									className={activeIndex === 'nomileage' ? 'active' : ''}
									onClick={() => filterResult('nomileage')}
								>
									Без пробега
								</li>
								<li
									className={activeIndex === 'quadbike' ? 'active' : ''}
									onClick={() => filterResult('quadbike')}
								>
									Квадроциклы
								</li>
								<li
									className={activeIndex === 'scooter' ? 'active' : ''}
									onClick={() => filterResult('scooter')}
								>
									Скутеры
								</li>
								<li
									className={activeIndex === 'new' ? 'active' : ''}
									onClick={() => filterResult('new')}
								>
									Новинки
								</li>
								<li
									className={activeIndex === 'specialoffers' ? 'active' : ''}
									onClick={() => filterResult('specialoffers')}
								>
									Спецпредложения
								</li>
							</ul>
						</Box>
						<Box sx={{ color: 'white', mb: 2 }}>
							{selectedCategory && (
								<h1 className='titleMain'>
									{categoryLabels[selectedCategory] || selectedCategory}
								</h1>
							)}
							{selectedTag && <p>Поиск по тегу: {selectedTag}</p>}
						</Box>
					</Box>
				</Box>

				<Row className='contentAnimProd'>
					<Col xs={0} lg={12} xl={12} style={{ position: 'relative' }}>
						{loading ? (
							<Box
								sx={{
									height: { md: '100%', xs: '61vh' },
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Box className='spinner-grow' role='status'>
									<span className='visually-hidden'>Loading...</span>
								</Box>
							</Box>
						) : data.length > 0 ? (
							<Row>
								{selectedCategory === 'quadbike' && (
									<Col xs={12} lg={3} xl={3}>
										<Box className='FilterQuadbike'>
											<p
												style={{
													margin: '0px',
													fontWeight: '400',
													fontSize: '1.2rem',
												}}
											>
												Фильтрация
											</p>
											<select
												className='inputLogin d22'
												onChange={e => setMaximumSpeed(e.target.value)}
												value={maximumSpeed}
											>
												<option value=''>Максимальная скорость</option>
												<option value='10'>10 км/ч</option>
												<option value='20'>20 км/ч</option>
												<option value='30'>30 км/ч</option>
												<option value='40'>40 км/ч</option>
												<option value='50'>50 км/ч</option>
												<option value='60'>60 км/ч</option>
												<option value='70'>70 км/ч</option>
												<option value='80'>80 км/ч</option>
												<option value='90'>90 км/ч</option>
												<option value='100'>100 км/ч</option>
											</select>
											<select
												className='inputLogin d22'
												onChange={e => setMaximumLoad(e.target.value)}
												value={maximumLoad}
											>
												<option value=''>Максимальная нагрузка</option>
												<option value='50'>50 кг</option>
												<option value='100'>100 кг</option>
												<option value='150'>150 кг</option>
												<option value='200'>200 кг</option>
												<option value='250'>250 кг</option>
												<option value='300'>300 кг</option>
											</select>
											<select
												className='inputLogin d22'
												onChange={e => setVolumePower(e.target.value)}
												value={volumePower}
											>
												<option value=''>Объем / мощность</option>
												<option value='50'>50 см³</option>
												<option value='75'>75 см³</option>
												<option value='100'>100 см³</option>
												<option value='125'>125 см³</option>
												<option value='150'>150 см³</option>
												<option value='200'>200 см³</option>
												<option value='250'>250 см³</option>
												<option value='300'>300 см³</option>
											</select>
											<select
												className='inputLogin d22'
												onChange={e => setEngineBike(e.target.value)}
												value={engineBike}
											>
												<option value=''>Тип двигателя</option>
												<option value='Бензиновый'>Бензиновый</option>
												<option value='Дизельный'>Дизельный</option>
												<option value='Электрический'>Электрический</option>
											</select>
										</Box>
									</Col>
								)}
								{selectedCategory === 'scooter' && (
									<Col xs={12} lg={3} xl={3}>
										<Box className='FilterQuadbike'>
											<p
												style={{
													margin: '0px',
													fontWeight: '400',
													fontSize: '1.2rem',
												}}
											>
												Фильтрация
											</p>
											<select
												className='inputLogin d22'
												onChange={e => setMaximumSpeed(e.target.value)}
												value={maximumSpeed}
											>
												<option value=''>Максимальная скорость</option>
												<option value='10'>10 км/ч</option>
												<option value='20'>20 км/ч</option>
												<option value='25'>25 км/ч</option>
												<option value='30'>30 км/ч</option>
												<option value='35'>35 км/ч</option>
												<option value='40'>40 км/ч</option>
												<option value='50'>50 км/ч</option>
												<option value='60'>60 км/ч</option>
												<option value='70'>70 км/ч</option>
												<option value='80'>80 км/ч</option>
												<option value='90'>90 км/ч</option>
												<option value='100'>100 км/ч</option>
											</select>
											<select
												className='inputLogin d22'
												onChange={e => setMaximumLoad(e.target.value)}
												value={maximumLoad}
											>
												<option value=''>Максимальная нагрузка</option>
												<option value='50'>50 кг</option>
												<option value='100'>100 кг</option>
												<option value='110'>110 кг</option>
												<option value='120'>120 кг</option>
												<option value='125'>125 кг</option>
												<option value='130'>130 кг</option>
												<option value='150'>150 кг</option>
												<option value='200'>200 кг</option>
												<option value='250'>250 кг</option>
												<option value='300'>300 кг</option>
											</select>
											<select
												className='inputLogin d22'
												onChange={e => setVolumePower(e.target.value)}
												value={volumePower}
											>
												<option value=''>Запас хода</option>
												<option value='40'>40 км</option>
												<option value='50'>50 км</option>
												<option value='60'>60 км</option>
												<option value='70'>70 км</option>
												<option value='80'>80 км</option>
											</select>
											<select
												className='inputLogin d22'
												onChange={e => setEngineBike(e.target.value)}
												value={engineBike}
											>
												<option value=''>Тип двигателя</option>
												<option value='Бензиновый'>Бензиновый</option>
												<option value='Дизельный'>Дизельный</option>
												<option value='Электрический'>Электрический</option>
											</select>
										</Box>
									</Col>
								)}

								{(() => {
									const filteredData = filterByCriteria()

									if (
										filteredData.length === 0 &&
										(selectedCategory === 'quadbike' ||
											selectedCategory === 'scooter')
									) {
										const fullCategoryData = posts.items.filter(
											item =>
												item.category === categoryMapping[selectedCategory]
										)
										return fullCategoryData.reverse().map((obj, k) => (
											<Col xs={12} lg={4} md={6} xl={3} key={k}>
												<Card
													tags={obj.tags}
													_id={obj._id}
													title={obj.title}
													imageUrl={
														obj.imageUrl
															? `${linkDev}${obj.imageUrl}`
															: avadrive
													}
													price={obj.price}
													isEditable={userData?._id === obj.user._id}
													game={obj}
													transmission={obj.transmission}
													mileage={obj.mileage}
													engine={obj.engine}
													power={obj.power}
													category={obj.category}
													onTagClick={handleTagClick}
													maximumSpeed={obj.maximumSpeed}
													maximumLoad={obj.maximumLoad}
													volumePower={obj.volumePower}
													engineBike={obj.engineBike}
												/>
											</Col>
										))
									}

									return filteredData.reverse().map((obj, k) => (
										<Col xs={12} lg={4} md={6} xl={3} key={k}>
											<Card
												tags={obj.tags}
												_id={obj._id}
												title={obj.title}
												imageUrl={
													obj.imageUrl ? `${linkDev}${obj.imageUrl}` : avadrive
												}
												price={obj.price}
												isEditable={userData?._id === obj.user._id}
												game={obj}
												transmission={obj.transmission}
												mileage={obj.mileage}
												engine={obj.engine}
												power={obj.power}
												category={obj.category}
												onTagClick={handleTagClick}
												maximumSpeed={obj.maximumSpeed}
												maximumLoad={obj.maximumLoad}
												volumePower={obj.volumePower}
												engineBike={obj.engineBike}
											/>
										</Col>
									))
								})()}
							</Row>
						) : (
							<Box
								sx={{
									textAlign: 'center',
									height: { md: '100%', xs: '71vh' },
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Box>
									<p>Ничего не найдено</p>
								</Box>
							</Box>
						)}
					</Col>
				</Row>
				{selectedCategory === 'quadbike' && <TextDesc />}
				{selectedCategory === 'scooter' && <TextDescScooter />}
				{(selectedCategory === 'withmileage' ||
					selectedCategory === 'nomileage') && <TextDescCar />}
			</>
		</Container>
	)
}
